import React, { useState, useEffect } from "react";
import AppConstants from "../constants/app_constants";

const Carousel = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [fade, setFade] = useState(true);

  useEffect(() => {
    const interval = setInterval(() => {
      setFade(false); // Start fade out
      setTimeout(() => {
        setCurrentIndex(
          (prevIndex) => (prevIndex + 1) % AppConstants.countries.length
        );
        setFade(true); // Fade in for next slide
      }, 500); // Allow time for fade out before changing slide
    }, 8000);

    return () => clearInterval(interval);
  }, []);

  const country = AppConstants.countries[currentIndex];

  return (
    <div className="relative w-full h-screen overflow-hidden font-poppins">
      <style>
        {`
          @keyframes zoom {
            0%, 100% {
              transform: scale(1);
            }
            50% {
              transform: scale(1.05);
            }
          }

          @keyframes fadeInOut {
            0% {
              opacity: 0;
            }
            100% {
              opacity: 1;
            }
          }

          @keyframes slideUp {
            0% {
              transform: translateY(20px);
              opacity: 0;
            }
            100% {
              transform: translateY(0);
              opacity: 1;
            }
          }
        `}
      </style>
      <div
        className="absolute inset-0 bg-cover bg-center transition-opacity duration-1000"
        style={{
          backgroundImage: `url(${country.imageUrl})`,
          animation: fade
            ? "zoom 10s ease-in-out infinite, fadeInOut 1s"
            : "none",
          opacity: fade ? 1 : 0,
        }}
      ></div>
      <div className="absolute inset-0 bg-black bg-opacity-50"></div>
      <div className="absolute inset-0 flex items-center justify-center p-4">
        <div className="text-center text-white">
          <h2
            className="text-4xl lg:text-6xl font-bold"
            style={{
              animation: fade ? "slideUp 1s ease-in-out" : "none",
              color: "#41acda",
            }}
          >
            {country.name}
          </h2>
          <p
            className="mt-4 text-xl lg:text-4xl"
            style={{
              animation: fade ? "slideUp 2s ease-in-out" : "none",
            }}
          >
            {country.description}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Carousel;
