// import France from "../assets/france.webp";
// import Italy from "../assets/italy.webp";
// import Spain from "../assets/spain.webp";
// import Germany from "../assets/germany.webp";
// import Greece from "../assets/greece.webp";
// import Portugal from "../assets/portugal.webp";
// import Sweden from "../assets/sweden.webp";
// import Norway from "../assets/norway.webp";
// import Poland from "../assets/poland.webp";
// import Netherlands from "../assets/netherlands.webp";

// import Hotels from "../assets/hotel2.png";
// import Hotels2 from "../assets/hotel3.webp";
// import Hotels3 from "../assets/hotel4.webp";
// import Visa from "../assets/visa2.webp";
// import Visa2 from "../assets/visa3.png";
// import Visa3 from "../assets/visa4.png";
// import UmrahPackages from "../assets/umrah_packages2.png";
// import UmrahPackages2 from "../assets/umrah_packages3.png";
// import UmrahPackages3 from "../assets/umrah_packages4.webp";
// import Ticket from "../assets/ticket.png";
// import Applications from "../assets/applications2.webp";
// import Applications2 from "../assets/applications3.png";
// import Applications3 from "../assets/detailApplications.webp";
// import MoneyTransfer from "../assets/money_transfer2.webp";
// import MoneyTransfer2 from "../assets/money_transfer3.png";
// import MoneyTransfer3 from "../assets/money_transfer4.webp";

// import DUmrah from "../assets/umrah_packages.png";
// import DFlights from "../assets/flight.jpg";
// import DVisa from "../assets/visa.png";
// import DMoneyTransfer from "../assets/money_transfer.png";
// import DHotel from "../assets/hotel.png";
// import DApplication from "../assets/applications.png";
// import { image } from "@cloudinary/url-gen/qualifiers/source";

export const images = {
  france:
    "https://res.cloudinary.com/dv9henoh0/image/upload/v1720706927/kykoq06qxtj2fnm6y6du.webp",
  italy:
    "https://res.cloudinary.com/dv9henoh0/image/upload/v1720706925/huxla068ng7kn7vzkmc1.webp",
  spain:
    "https://res.cloudinary.com/dv9henoh0/image/upload/v1720706929/pwanewccjz0h0bqcv7sf.webp",
  norway:
    "https://res.cloudinary.com/dv9henoh0/image/upload/v1720706927/debqrcuol14dsf9cmdhw.webp",
  netherlands:
    "https://res.cloudinary.com/dv9henoh0/image/upload/v1720706927/rs6vomd46gg5xzjgy6p5.webp",
  germany:
    "https://res.cloudinary.com/dv9henoh0/image/upload/v1720706927/hksh0pmqjcil5uq9nwv1.webp",
  greece:
    "https://res.cloudinary.com/dv9henoh0/image/upload/v1720706928/rfw78olapzqslsfex4j8.webp",
  portugal:
    "https://res.cloudinary.com/dv9henoh0/image/upload/v1720706928/ip8s0fwkdpp0vbuczrog.webp",
  poland:
    "https://res.cloudinary.com/dv9henoh0/image/upload/v1720706928/mrqktgmobvuuthbhvee8.webp",
  sweden:
    "https://res.cloudinary.com/dv9henoh0/image/upload/v1720706929/c1q74mx6g3dxaknxpzr5.webp",
  visa: "https://res.cloudinary.com/dv9henoh0/image/upload/v1720706930/za81qycilqe1e2bbgaow.png",
  visa2:
    "https://res.cloudinary.com/dv9henoh0/image/upload/v1720706931/xfascfddzwcabqlzf2ja.webp",
  visa3:
    "https://res.cloudinary.com/dv9henoh0/image/upload/v1720706931/pezj26sv3gsydcj5wj6y.webp",
  visa4:
    "https://res.cloudinary.com/dv9henoh0/image/upload/v1720706931/qyzfo8aen5oyu6dhe0qu.webp",
  umrahpackages:
    "https://res.cloudinary.com/dv9henoh0/image/upload/v1720706930/p4dldi9pj3yhuq5bjwrc.png",
  umrahpackages2:
    "https://res.cloudinary.com/dv9henoh0/image/upload/v1720706930/whd5unqvsua7jsquxi63.jpg",
  umrahpackages3:
    "https://res.cloudinary.com/dv9henoh0/image/upload/v1720706930/cwpeyvrkpgg2ryunqqna.jpg",
  umrahpackages4:
    "https://res.cloudinary.com/dv9henoh0/image/upload/v1720706930/dukxth0mdc5zsykall27.webp",
  ticket:
    "https://res.cloudinary.com/dv9henoh0/image/upload/v1720706929/wv61weke0sbms4fsehel.jpg",
  hotel:
    "https://res.cloudinary.com/dv9henoh0/image/upload/v1720706927/p7nmjf12e5dyvia5tldd.png",
  hotel2:
    "https://res.cloudinary.com/dv9henoh0/image/upload/v1720706929/mhzft1fp7yst8lihqp8z.jpg",
  hotel3:
    "https://res.cloudinary.com/dv9henoh0/image/upload/v1720706924/kuxn8u48xg99jtzzguqu.webp",
  hotel4:
    "https://res.cloudinary.com/dv9henoh0/image/upload/v1720706925/gfbhwt7cntzg6bk73uum.webp",
  moneyTransfer:
    "https://res.cloudinary.com/dv9henoh0/image/upload/v1720706926/tz3zpc8ng5vyo2ygfv0f.png",
  moneyTransfer2:
    "https://res.cloudinary.com/dv9henoh0/image/upload/v1720706926/umymofkmpcwlrpgbeoqx.webp",
  moneyTransfer3:
    "https://res.cloudinary.com/dv9henoh0/image/upload/v1720706926/gy6ygrvh3zspmvgedess.jpg",
  moneyTransfer4:
    "https://res.cloudinary.com/dv9henoh0/image/upload/v1720706927/rl9joihyqdk4wyrrwzrc.webp",
  flight:
    "https://res.cloudinary.com/dv9henoh0/image/upload/v1720706927/a6a86jr38axfg9rqcrrc.png",
  detailApplication:
    "https://res.cloudinary.com/dv9henoh0/image/upload/v1720706926/vkqx5a7oo9pyn48jchb4.webp",
  application:
    "https://res.cloudinary.com/dv9henoh0/image/upload/v1720706924/lmah4p7zlg8srsxm0yfv.png",
  application2:
    "https://res.cloudinary.com/dv9henoh0/image/upload/v1720706924/meh6yzjuxuymonkpfg47.webp",
  application3:
    "https://res.cloudinary.com/dv9henoh0/image/upload/v1720706924/j4rmebgom26h4yuaov7k.jpg",
};

class AppConstants {
  static countries = [
    {
      name: "France",
      description: "Known for its wines and sophisticated cuisine",
      imageUrl: images.france,
    },
    {
      name: "Italy",
      description:
        "Famous for its monumental contributions to art, architecture, and food",
      imageUrl: images.italy,
    },
    {
      name: "Spain",
      description: "Famous for its festive fiestas and vibrant art scenes",
      imageUrl: images.spain,
    },
    {
      name: "Germany",
      description: "Known for its precision engineering and high-tech products",
      imageUrl: images.germany,
    },
    {
      name: "Greece",
      description: "Famous for its beaches and ancient ruins",
      imageUrl: images.greece,
    },
    {
      name: "Portugal",
      description: "Known for its scenic landscapes and rich history",
      imageUrl: images.portugal,
    },
    {
      name: "Sweden",
      description: "Known for its innovation and strong welfare state",
      imageUrl: images.sweden,
    },
    {
      name: "Norway",
      description: "Famous for its fjords and stunning natural landscapes",
      imageUrl: images.norway,
    },
    {
      name: "Poland",
      description: "Known for its medieval architecture and hearty cuisine",
      imageUrl: images.poland,
    },
    {
      name: "Netherlands",
      description:
        "Famous for its flat landscape, cycling routes, and tulip fields",
      imageUrl: images.netherlands,
    },
  ];

  static services = [
    {
      id: 1,
      title: "Umrah Packages",
      short_description:
        "Offering tailored Umrah packages that cater to all your spiritual and comfort needs during your sacred journey.",
      long_description:
        "Our Umrah packages are carefully curated to provide a deeply spiritual and hassle-free experience. Each package includes comprehensive visa processing, return flights, luxury accommodations within walking distance of the Haram in Makkah and the Prophet's Mosque in Madinah, transportation, and guided tours. We also offer special lectures and educational programs to enrich your understanding of the rites of Umrah and the history of the sites you will visit.",
      images: [images.umrahpackages2, images.umrahpackages3, images.umrahpackages4],
      shortImage: images.umrahpackages,
    },
    {
      id: 2,
      title: "Visa",
      short_description:
        "Fast and reliable visa services to ensure your travel plans go smoothly.",
      long_description:
        "Navigating the complexities of visa applications can be challenging. Our expert team offers assistance with visa applications, including documentation verification, appointment scheduling, and embassy submissions. We specialize in tourist, business, and transit visas, and provide up-to-date information on visa requirements and changes in immigration laws to ensure your application process is as smooth as possible.",
      images: [images.visa2, images.visa3, images.visa4],
      shortImage: images.visa,
    },
    {
      id: 3,
      title: "Hotels",
      short_description:
        "Book top-rated hotels at the best prices across the globe.",
      long_description:
        "We connect you with a vast network of hotel options from our carefully selected partners to guarantee the best stay experience. Whether you're looking for luxury resorts with world-class amenities, charming boutique hotels, or cost-effective business hotels, we have something for every type of traveler. Our partnerships with hotels also allow us to offer exclusive deals and amenities not available elsewhere.",
      images: [images.hotel2, images.hotel3, images.hotel4],
      shortImage: images.hotel,
    },
    {
      id: 4,
      title: "Flights",
      short_description:
        "Secure the best deals on flights to destinations worldwide.",
      long_description:
        "Our flight booking service offers you a seamless travel planning experience. We provide a broad selection of flights across multiple airlines and partnerships, ensuring you get the best prices and convenient flight schedules. Whether you are planning a multi-city tour or need a last-minute one-way ticket, our user-friendly platform and personalized customer service are here to assist you every step of the way.",
      images: [images.ticket],
      shortImage: images.flight,
    },
    {
      id: 5,
      title: "Applications",
      short_description:
        "Comprehensive support for all your travel-related applications.",
      long_description:
        "Our application assistance extends beyond travel bookings to include travel insurance procurement, visa applications, and special permits. We ensure you meet all international travel requirements, with a focus on safety and compliance. We provide comprehensive support throughout your travel preparation, making your journey as seamless and stress-free as possible.",
      images: [images.application2, images.application3, images.detailApplication],
      shortImage: images.application,
    },
    {
      id: 6,
      title: "Money Transfer",
      short_description:
        "Fast, secure, and low-cost money transfer services to over 130 countries.",
      long_description:
        "Utilize our trusted money transfer services to send funds internationally quickly and securely. Our platform supports multiple payment methods and currencies, ensuring your money reaches its destination without unnecessary delays or high fees. We offer real-time tracking of transfers and ensure compliance with international financial regulations to provide you with peace of mind.",
      images: [images.moneyTransfer2, images.moneyTransfer3, images.moneyTransfer4],
      shortImage: images.moneyTransfer,
    },
  ];
}

export default AppConstants;
