import React, { useState, useEffect } from "react";
// import France from "../assets/france.webp";
// import Italy from "../assets/italy.webp";
// import Spain from "../assets/spain.webp";
// import Germany from "../assets/germany.webp";
// import Greece from "../assets/greece.webp";
// import Portugal from "../assets/portugal.webp";
// import Sweden from "../assets/sweden.webp";
// import Norway from "../assets/norway.webp";
// import Poland from "../assets/poland.webp";
// import Netherlands from "../assets/netherlands.webp";
import { useNavigate } from "react-router-dom";

export const images = {
  france:
    "https://res.cloudinary.com/dv9henoh0/image/upload/v1720706927/kykoq06qxtj2fnm6y6du.webp",
  italy:
    "https://res.cloudinary.com/dv9henoh0/image/upload/v1720706925/huxla068ng7kn7vzkmc1.webp",
  spain:
    "https://res.cloudinary.com/dv9henoh0/image/upload/v1720706929/pwanewccjz0h0bqcv7sf.webp",
  norway:
    "https://res.cloudinary.com/dv9henoh0/image/upload/v1720706927/debqrcuol14dsf9cmdhw.webp",
  netherlands:
    "https://res.cloudinary.com/dv9henoh0/image/upload/v1720706927/rs6vomd46gg5xzjgy6p5.webp",
  germany:
    "https://res.cloudinary.com/dv9henoh0/image/upload/v1720706927/hksh0pmqjcil5uq9nwv1.webp",
  greece:
    "https://res.cloudinary.com/dv9henoh0/image/upload/v1720706928/rfw78olapzqslsfex4j8.webp",
  portugal:
    "https://res.cloudinary.com/dv9henoh0/image/upload/v1720706928/ip8s0fwkdpp0vbuczrog.webp",
  poland:
    "https://res.cloudinary.com/dv9henoh0/image/upload/v1720706928/mrqktgmobvuuthbhvee8.webp",
  sweden:
    "https://res.cloudinary.com/dv9henoh0/image/upload/v1720706929/c1q74mx6g3dxaknxpzr5.webp",
};

const countries = [
  {
    name: "France",
    description: "Known for its wines and sophisticated cuisine",
    imageUrl: images.france,
  },
  {
    name: "Italy",
    description:
      "Famous for its monumental contributions to art, architecture, and food",
    imageUrl: images.italy,
  },
  {
    name: "Spain",
    description: "Famous for its festive fiestas and vibrant art scenes",
    imageUrl: images.spain,
  },
  {
    name: "Germany",
    description: "Known for its precision engineering and high-tech products",
    imageUrl: images.germany,
  },
  {
    name: "Greece",
    description: "Famous for its beaches and ancient ruins",
    imageUrl: images.greece,
  },
  {
    name: "Portugal",
    description: "Known for its scenic landscapes and rich history",
    imageUrl: images.portugal,
  },
  {
    name: "Sweden",
    description: "Known for its innovation and strong welfare state",
    imageUrl: images.sweden,
  },
  {
    name: "Norway",
    description: "Famous for its fjords and stunning natural landscapes",
    imageUrl: images.norway,
  },
  {
    name: "Poland",
    description: "Known for its medieval architecture and hearty cuisine",
    imageUrl: images.poland,
  },
  {
    name: "Netherlands",
    description:
      "Famous for its flat landscape, cycling routes, and tulip fields",
    imageUrl: images.netherlands,
  },
];

const Carousel = () => {
  const navigate = useNavigate();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [fade, setFade] = useState(true);

  useEffect(() => {
    const interval = setInterval(() => {
      setFade(false); // Start fade out
      setTimeout(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % countries.length);
      }, 500);
      setTimeout(() => {
        navigate("/home");
      }, 4000);
    }, 2000);

    return () => clearInterval(interval);
  }, []);

  const country = countries[currentIndex];

  return (
    <div className="relative w-full h-screen overflow-hidden font-poppins">
      <style>
        {`
            @keyframes zoom {
              0%, 100%{
                transform: scale(1);
              }
              50% {
                transform: scale(1.05);
              }
            }
  
            @keyframes fadeIn {
              0% {
                opacity: 0;
              }
              100% {
                opacity: 1;
              }
            }
          `}
      </style>
      <div
        className="absolute inset-0 bg-cover bg-center"
        style={{
          backgroundImage: `url(${country.imageUrl})`,
          animation: "zoom 10s ease-in-out infinite",
        }}
      ></div>
      <div className="absolute inset-0 bg-black bg-opacity-50"></div>
      <div className="absolute inset-0 flex items-center justify-center p-4">
        <div className="text-center text-white">
          <h2
            className="text-7xl font-bold"
            style={{ animation: `${fade ? "fadeIn 4s linear" : "none"}` }}
          >
            Welcome To <span style={{ color: "#41acda" }}>Paswal Tours</span>
          </h2>
        </div>
      </div>
    </div>
  );
};

export default Carousel;
