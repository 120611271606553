import React, { useEffect, useState } from "react";
import TravelCard from "./TravelCard";
// import tropicalImage from "../assets/tropic_places.jpg";
// import stunningImage from "../assets/far_places.webp";
// import relaxingImage from "../assets/relaxing.jpg";
// import culturalImage from "../assets/umrah_people.jpg";
// import backgroundImage from "../assets/destination.webp";
import GalleryComponent from "./GalleryComponent";
import { useLocation } from "react-router-dom";

export const images = {
  destination:
    "https://res.cloudinary.com/dv9henoh0/image/upload/v1720706926/oe3ttm7ibjtutodw4yrb.webp",
  tropic_places:
    "https://res.cloudinary.com/dv9henoh0/image/upload/v1720706929/u5odeuccmkcjwp7jr49z.jpg",
  far_places:
    "https://res.cloudinary.com/dv9henoh0/image/upload/v1720706926/pzmx2vwwzsaidqkomshv.webp",
  relaxing:
    "https://res.cloudinary.com/dv9henoh0/image/upload/v1720706929/xrqmndjbuepso3fothzh.jpg",
  umrahPeople:
    "https://res.cloudinary.com/dv9henoh0/image/upload/v1720706931/mjutj9sku2rv8kdxwfiy.jpg",
};

function Destination() {
  const location = useLocation();
  const [fade, setFade] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    setFade(true);
  }, [location]);

  return (
    <div className="font-poppins">
      <div
        className="relative text-white font-poppins w-full flex items-center h-screen flex-col justify-center"
        style={{
          backgroundImage: `url(${images.destination})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="absolute inset-0 bg-black bg-opacity-50"></div>
        <div className="z-10 text-center">
          <h2
            className="text-4xl lg:text-6xl font-bold"
            style={{
              animation: fade ? "slideUp 1s ease-in-out" : "none",
              color: "#41acda",
            }}
          >
            Destinations
          </h2>

          <h1
            className="mt-4 text-xl lg:text-4xl"
            style={{
              animation: fade ? "slideUp 2s ease-in-out" : "none",
            }}
          >
            Explore Destinations, Create Memories with Paswal Tours.
          </h1>
        </div>
      </div>

      <h1 className="text-4xl lg:text-6xl font-bold text-dark-blue-col text-center p-8">
        Beautiful Destinations
      </h1>

      <h3 className="text-md lg:text-xl text-dark-blue-col text-center">
        Discover the World's Beauty with Paswal Tours:{" "}
        <strong>Where Beautiful Destinations Await.</strong>
      </h3>

      <div className="flex flex-wrap justify-center items-center p-5">
        <TravelCard
          image={images.tropic_places}
          title="Tropical Exotic Places"
          description="Explore serene tropical destinations."
        />
        <TravelCard
          image={images.far_places}
          title="Stunning Far Places"
          description="Journey to stunning distant locations."
        />
        <TravelCard
          image={images.relaxing}
          title="Relaxing Holiday"
          description="Unwind on your relaxing holiday."
        />
        <TravelCard
          image={images.umrahPeople}
          title="Spiritual Journey"
          description="Experience the peace and beauty of Umrah"
        />
      </div>
      <GalleryComponent />
    </div>
  );
}

export default Destination;
