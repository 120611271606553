import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import defaultUserImage from "../assets/default-user.png";

export const images = {
  defaultUser:
    "https://res.cloudinary.com/dv9henoh0/image/upload/v1720706925/spqkh24djoykwbxssbn9.png",
};

const Reviews = () => {
  const settings = {
    dots: true,
    speed: 500,
    slidesToShow: 4,
    infinite: false,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const testimonials = [
    {
      name: "Sultan",
      position: "User",
      text: "Amazing customer service very friendly and welcoming, felt like I was part of a family, excellent rates and I believe is the best travel service in Bolton.",
    },
    {
      name: "Sayed Hussain",
      position: "User",
      text: "Superb customer service. Staff are friendly and multilingual which made it easy for me to organise my flight. Would recommend.",
    },
    {
      name: "Aleem Syed",
      position: "User",
      text: "Did our COVID test here. The results come quick and as committed. Also the staff is multilingual. They give you time when booking tickets to try and find cheapest options possible.",
    },
    {
      name: "Armaan Ambar",
      position: "User",
      text: "Excellent service and best rating in Bolton! absolutley loved the service from the young lad!",
    },
    {
      name: "Waqar Raja",
      position: "User",
      text: "Very nice and helpful travel agency. I would recommend booking tickets with them.",
    },
    {
      name: "Lewis",
      position: "User",
      text: "Excellent service it was fast and i was out in no time.",
    },
    {
      name: "SalimA-27",
      position: "User",
      text: "Reliable courteous service.",
    },
    {
      name: "SalimA-26",
      position: "User",
      text: "Courteous service reliable.",
    },
    {
      name: "AhmedJ-25",
      position: "User",
      text: "Absolutely fantastic i went there things were done really quickly and the staff were really polite really appreciate it.",
    },
  ];

  return (
    <div
      className="w-full py-8 font-poppins"
      style={{
        background: "linear-gradient(to bottom, #272f32 50%, #ffffff 50%)",
      }}
    >
      <h2 className="text-center text-3xl font-bold mb-8 text-white">
        What do our users say?
      </h2>
      <div className="container mx-auto px-4 sm:px-6 lg:px-8">
        <Slider {...settings}>
          {testimonials.map((testimonial, index) => (
            <div key={index} className="p-4">
              <div
                className="max-w-sm mx-auto bg-tertiary-col rounded-2xl shadow-md overflow-hidden w-64 transform transition-transform duration-300 hover:scale-105"
                style={{ minHeight: "400px" }}
              >
                <div className="px-6 py-4">
                  <div className="flex justify-center mb-4">
                    <img
                      src={images.defaultUser}
                      alt={testimonial.name}
                      className="w-16 h-16 rounded-full"
                    />
                  </div>
                  <h3 className="text-lg font-bold text-white text-center">
                    {testimonial.name}
                  </h3>
                  <p className="text-white-600 text-center">
                    {testimonial.position}
                  </p>
                  <p className="mt-4 text-white text-center">
                    {testimonial.text}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default Reviews;
