import React, { useState } from "react";
import emailjs from "emailjs-com";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ContactForm = () => {
  const [formData, setFormData] = useState({
    fullName: "",
    phoneNumber: "",
    email: "",
    subject: "",
    message: "",
  });

  const images = {
    contact_us_img: "https://res.cloudinary.com/dv9henoh0/image/upload/v1720706925/mmwr6rnvsf8inunungow.jpg",
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const { fullName, phoneNumber, email, subject, message } = formData;

    if (!fullName || !phoneNumber || !email || !subject || !message) {
      toast.error("All fields are mandatory!");
      return;
    }

    if (!validateEmail(email)) {
      toast.error("Please enter a valid email address.");
      return;
    }

    const templateParams = {
      from_name: formData.fullName,
      message: `
      Email: ${formData.email}
      Phone Number: ${formData.phoneNumber}
      Subject: ${formData.subject}
      Message: ${formData.message}
    `,
    };

    emailjs
      .send(
        "service_ob2qnjs",
        "template_f3xws2l",
        templateParams,
        "XH8z979n83zVvBoEh"
      )
      .then(
        (response) => {
          console.log("SUCCESS!", response.status, response.text);
          toast.success(
            "Message sent successfully, Paswal Tours will contact you within 24 hours!"
          );
        },
        (error) => {
          console.log("FAILED...", error);
          toast.error("Failed to send the message, please try again later.");
        }
      );

    // Reset form fields
    setFormData({
      fullName: "",
      phoneNumber: "",
      email: "",
      subject: "",
      message: "",
    });
  };

  return (
    <div className="bg-primary-col py-5 rounded-lg shadow-lg mx-auto font-poppins">
      <ToastContainer />
      <div className="flex flex-col lg:flex-row justify-center">
        <div className="flex-auto hidden lg:block p-10 lg:p-20 w-full lg:w-1/2">
          <img
            src={images.contact_us_img}
            alt="Collaborative environment"
            className="h-full w-full object-cover rounded-lg"
          />
        </div>
        <div className="flex-auto p-5 lg:w-1/2">
          <div className="mb-5">
            <h2 className="text-xl sm:text-2xl mb-2" style={{ color: "#41acda" }}>
              Contact Us
            </h2>
            <p className="text-white font-bold text-2xl sm:text-3xl">Let's Connect Now!</p>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <label className="font-bold text-white">Full Name</label>
              <input
                type="text"
                name="fullName"
                className="w-full mt-1 p-2 border border-gray-300 rounded-md text-sm sm:text-base"
                placeholder="Full Name"
                value={formData.fullName}
                onChange={handleChange}
              />
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-4">
              <div>
                <label className="font-bold text-white">Phone Number</label>
                <input
                  type="tel"
                  name="phoneNumber"
                  className="w-full mt-1 p-2 border border-gray-300 rounded-md text-sm sm:text-base"
                  placeholder="Phone Number"
                  value={formData.phoneNumber}
                  onChange={handleChange}
                />
              </div>
              <div>
                <label className="font-bold text-white">Email Address</label>
                <input
                  type="email"
                  name="email"
                  className="w-full p-2 mt-1 border border-gray-300 rounded-md text-sm sm:text-base"
                  placeholder="Email"
                  value={formData.email}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="mb-4">
              <label className="mb-3 font-bold text-white">Subject</label>
              <input
                type="text"
                name="subject"
                className="w-full p-2 mt-1 border border-gray-300 rounded-md text-sm sm:text-base"
                value={formData.subject}
                onChange={handleChange}
              />
            </div>
            <div className="mb-6">
              <label className="font-bold text-white">Message</label>
              <textarea
                name="message"
                className="w-full mt-1 p-2 border border-gray-300 rounded-md text-sm sm:text-base"
                rows="5"
                value={formData.message}
                onChange={handleChange}
              ></textarea>
            </div>
            <div className="text-start">
              <button
                type="submit"
                className="bg-tertiary-col text-white px-4 py-2 mt-3 md:px-6 md:py-3 font-bold rounded-full shadow-lg hover:bg-gray-600 transition-colors duration-300 text-sm sm:text-base"
              >
                Send Message
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ContactForm;
