import React, { useState, useEffect } from "react";
import ContactForm from "./ContactForm";
import { useLocation } from "react-router-dom";

export const images = {
  about_us: "https://res.cloudinary.com/dv9henoh0/image/upload/v1720706924/jalnubqm40aqrq8p74mb.webp",
  ourStory: "https://res.cloudinary.com/dv9henoh0/image/upload/v1720706928/sjiuffdrscfp1ete9yug.webp",
  whychooseus: "https://res.cloudinary.com/dv9henoh0/image/upload/v1720706931/hmbp1pdwhiucsyuowkbr.webp",
  ourservices: "https://res.cloudinary.com/dv9henoh0/image/upload/v1720706928/hl3ck0qwpeqxto8ggvvh.jpg",
};

function AboutUsDetail() {
  const location = useLocation();
  const [fade, setFade] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    setFade(true);
  }, [location]);

  return (
    <div className="font-poppins">
      <div
        className="relative text-white w-full flex items-center h-screen flex-col justify-center"
        style={{
          backgroundImage: `url(${images.about_us})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="absolute inset-0 bg-black bg-opacity-50"></div>
        <div className="z-10 text-center px-4 sm:px-0">
          <h2
            className="text-3xl sm:text-5xl font-bold"
            style={{
              animation: fade ? "slideUp 1s ease-in-out" : "none",
              color: "#41acda",
            }}
          >
            About Us
          </h2>
          <h1
            className="mt-4 text-xl sm:text-3xl"
            style={{
              animation: fade ? "slideUp 2s ease-in-out" : "none",
            }}
          >
            Embark on Extraordinary Journeys with Paswal Tours.
          </h1>
        </div>
      </div>

      <div className="p-5 sm:p-10 lg:px-20">
        <div className="flex flex-wrap md:flex-nowrap bg-white p-5 sm:p-10 rounded-2xl mb-10 shadow-xl">
          <div className="w-full md:w-1/2 flex flex-col justify-center pr-0 sm:pr-8 mb-5 md:mb-0">
            <h1
              className="text-3xl sm:text-4xl font-bold text-center mb-5"
              style={{ color: "#41acda" }}
            >
              Our Story
            </h1>
            <div className="text-justify text-lg font-serif">
              <p className="text-sm md:text-lg">
                Welcome to <strong>Paswal Tours</strong> — At Paswal Tours, your
                premier travel partner, we are dedicated to unlocking the globe
                for explorers and pilgrims alike. As an IATA-accredited agency,
                we specialize in delivering a wide range of high-quality travel
                services, including personalized flights, bespoke holiday
                packages, and spiritually enriching Umrah pilgrimages.
              </p>
              <p className="text-sm md:text-lg text-gray-500 py-5 italic">
                Whether you're looking to explore new cultures on a holiday,
                seeking a business trip, or embarking on a spiritual journey to
                perform Umrah, Paswal Tours is dedicated to providing
                exceptional service and memorable experiences. Our expert team
                is committed to handling all your travel needs with
                professionalism and care, ensuring every trip is as enjoyable
                and hassle-free as possible.
              </p>
            </div>
          </div>
          <div className="w-full md:w-1/2 flex justify-center">
            <img src={images.ourStory} alt="Our Story" className="rounded-2xl w-full md:w-auto" />
          </div>
        </div>

        <div className="flex flex-wrap md:flex-nowrap bg-white p-5 sm:p-10 rounded-2xl mb-10 shadow-xl">
          <div className="w-full md:w-1/2 flex justify-center order-1 md:order-none mb-5 md:mb-0">
            <img
              src={images.whychooseus}
              alt="Why Choose Us"
              className="rounded-2xl w-full md:w-auto"
            />
          </div>
          <div className="w-full md:w-1/2 flex flex-col justify-center pr-0 sm:pr-9 order-2 md:order-none">
            <h1
              className="text-3xl sm:text-4xl font-bold text-center mt-5"
              style={{ color: "#41acda" }}
            >
              Why Choose Us?
            </h1>
            <div className="text-justify text-lg font-serif mt-5 md:mt-0">
              <p className="text-sm md:text-lg lg:p-10">
                At Paswal Tours, we prioritize personalized service, ensuring
                that each trip is meticulously crafted to meet your specific
                travel needs and expectations. As an esteemed IATA member, we
                adhere to the highest standards of quality and reliability
                within the travel industry. Our comprehensive travel solutions
                cover everything from securing visas and travel insurance to
                arranging accommodations and ground transportation, providing
                you with a seamless travel experience. Above all, customer
                satisfaction remains our top priority; we deeply value our
                clients' feedback and are continually striving to enhance our
                services to better meet your travel needs.
              </p>
            </div>
          </div>
        </div>

        <div className="flex flex-wrap md:flex-nowrap bg-white p-5 sm:p-10 rounded-2xl mb-10 shadow-xl">
          <div className="w-full md:w-1/2 flex flex-col justify-center pr-0 sm:pr-8 mb-5 md:mb-0">
            <h1
              className="text-3xl sm:text-4xl font-bold text-center mb-5"
              style={{ color: "#41acda" }}
            >
              Our Services
            </h1>
            <div className="text-justify text-lg font-serif">
              <p className="text-sm md:text-lg">
                Our expertly curated holiday packages are designed to cater to a
                variety of travel preferences and budgets. From tropical beach
                vacations and luxury cruises to cultural tours and
                eco-adventures, Paswal Tours crafts itineraries that offer an
                in-depth exploration of the world's most enchanting
                destinations. For our Muslim clients, we provide comprehensive
                Umrah packages that not only include travel arrangements and
                accommodations but also guide and assist you in fulfilling your
                religious duties with tranquility and peace of mind.
              </p>
            </div>
          </div>
          <div className="w-full md:w-1/2 flex justify-center">
            <img
              src={images.ourservices}
              alt="Our Services"
              className="rounded-2xl w-full md:w-auto"
            />
          </div>
        </div>

        <div className="mt-10">
          <div className="rounded-2xl overflow-hidden shadow-xl">
            <ContactForm />
          </div>
        </div>
      </div>

      <style jsx>{`
        @media (max-width: 768px) {
          .w-full {
            width: 100%;
          }
          .md\\:w-1\\/2 {
            width: 100%;
          }
          .pr-0 {
            padding-right: 0;
          }
          .sm\\:pr-8 {
            padding-right: 0;
          }
          .rounded-2xl {
            border-radius: 1rem;
          }
          .text-justify {
            text-align: justify;
          }
          .mt-10 {
            margin-top: 2.5rem;
          }
          .bg-white {
            background-color: #ffffff;
          }
          .text-gray-500 {
            color: #6b7280;
          }
          .text-lg {
            font-size: 1.125rem;
          }
          .shadow-xl {
            box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
              0 10px 10px -5px rgba(0, 0, 0, 0.04);
          }
          .overflow-hidden {
            overflow: hidden;
          }
          .order-1 {
            order: 1;
          }
          .order-2 {
            order: 2;
          }
        }
      `}</style>
    </div>
  );
}

export default AboutUsDetail;
