import React from "react";
import { Link } from "react-router-dom";

const ServiceCard = ({ serviceId, title, description, shortImage }) => {
  return (
    <div className="bg-white shadow-lg p-4 md:p-6 h-auto md:h-72 w-full md:w-80 flex flex-col justify-between items-start text-start rounded-lg overflow-hidden">
      <img
        src={shortImage}
        alt={title}
        className="w-16 h-16 md:w-16 md:h-16 mb-3 object-cover"
      />
      <div className="flex flex-col flex-grow md:flex-row md:flex-col justify-between items-start">
        <div className="flex flex-col md:mr-4">
          <h3 className="mb-2 text-lg md:text-xl font-semibold">{title}</h3>
          <p className="text-sm">{description}</p>
        </div>
        <Link
          to={`/service-detail/${serviceId}`}
          className="mt-3 md:mt-0 md:self-end"
        >
          <button className="bg-tertiary-col text-white px-3 md:px-6 py-2 md:py-3 rounded-full shadow-lg hover:bg-gray-600 transition-colors duration-300">
            More
          </button>
        </Link>
      </div>
    </div>
  );
};

export default ServiceCard;
