import React, { useState, useEffect } from "react";
// import backgroundImage from "../assets/services.webp";
import ContactForm from "./ContactForm";
import { useLocation, useParams } from "react-router-dom";
import AppConstants from "../constants/app_constants";
import FlightForm from "./FlightForm";

export const images = {
  services:
    "https://res.cloudinary.com/dv9henoh0/image/upload/v1720706929/zxmybz7gosromqoxfkva.webp",
};

function ServiceDetail() {
  const { serviceId } = useParams(); // Getting the ID from the URL
  const [service, setService] = useState(null);
  const location = useLocation();
  const [fade, setFade] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    const foundService = AppConstants.services.find(
      (s) => s.id === parseInt(serviceId, 10)
    );
    setService(foundService);
    setFade(true);
  }, [serviceId, location]);

  return (
    <div className="font-poppins" style={{ backgroundColor: "#D8EFD3" }}>
      {/* Background Image Section */}
      <div
        className="relative text-white w-full flex items-center h-screen flex-col justify-center"
        style={{
          backgroundImage: `url(${images.services})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="absolute inset-0 bg-black bg-opacity-50"></div>
        <div className="z-10 text-center">
          <p className="text-4xl lg:text-6xl font-bold">Service</p>
          <h1
            className="mt-4 text-xl lg:text-4xl"
            style={{
              animation: fade ? "slideUp 1s ease-in-out" : "none",
              color: "#41acda",
              fontWeight: "bold",
            }}
          >
            {service?.title}
          </h1>
        </div>
      </div>

      {/* Main Content Section */}
      <div className="p-5 md:p-10 flex flex-col">
        {/* Service Title */}
        <div className="flex flex-col md:flex-row justify-stretch text-center md:text-start mb-5 mt-5 md:mt-10">
          <p className="text-gray-500 px-5 text-center md:text-start italic text-lg md:text-xl">
            {service?.id}
          </p>
          <h1 className="text-3xl md:text-4xl font-bold text-center md:text-start text-dark-blue-col">
            {service?.title}
          </h1>
        </div>

        {/* Service Details */}
        <div className="px-5 md:px-14 py-5 flex flex-col flex-grow text-justify font-serif text-lg md:text-xl italic">
          {/* Description */}
          <div className="w-full mb-5">
            <p className="text-sm md:text-lg">{service?.long_description}</p>
          </div>
          {/* Images */}
          <div className="flex flex-col md:flex-row flex-wrap justify-center">
            {service?.images && service.images.length > 0 ? (
              service.images.map((image, index) => (
                <div key={index} className="w-full md:w-1/2 lg:w-1/3 p-2">
                  <img
                    src={image}
                    className="mx-auto rounded-lg shadow-xl w-full h-72 md:h-96 object-cover"
                    alt={`${service.title} ${index + 1}`}
                  />
                  <p className="mt-3 text-sm">{service.imageDescriptions && service.imageDescriptions[index] ? service.imageDescriptions[index] : ""}</p>
                </div>
              ))
            ) : (
              <div className="w-full md:w-1/2 lg:w-1/3 p-3">
                <div className="bg-white rounded-lg shadow-lg p-3 text-center"></div>
              </div>
            )}
          </div>
        </div>

        {/* Contact Form */}
        <div className="mt-2">
          <div className="rounded-2xl overflow-hidden">
            {serviceId == 4 ? <FlightForm /> : <ContactForm />}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ServiceDetail;
