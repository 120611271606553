import React, { useRef, useEffect, useState } from "react";
// import backgroundImage from "../assets/why-paswal.webp";
import { NavLink } from "react-router-dom";

export const images = {
  whypaswal: "https://res.cloudinary.com/dv9henoh0/image/upload/v1720706931/ft7atr6fgtbccaayeoo8.webp"
};

const AboutUs = () => {
  const sectionRef = useRef(null);
  const [inView, setInView] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setInView(true);
        } else {
          setInView(false);
        }
      },
      {
        threshold: 0.1,
      }
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, []);

  return (
    <div
      ref={sectionRef}
      className={`relative text-white font-poppins w-full flex items-center transition-all duration-1000 ${
        inView
          ? "opacity-100 transform translate-y-0"
          : "opacity-0 transform translate-y-10"
      }`}
      style={{
        backgroundImage: `url(${images.whypaswal})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        borderRadius: "20px",
      }}
    >
      <div className="absolute inset-0 bg-black bg-opacity-50 rounded-2xl"></div>
      <div className="z-10 w-full grid grid-cols-1 md:grid-cols-2 gap-4 p-10 md:p-20">
        <div className="space-y-6">
          <h1 className="text-2xl md:text-3xl font-bold">
            WHY <span style={{ color: "#41acda" }}>PASWAL TOURS</span>?
          </h1>
          <p className="text-md md:text-md">
            Welcome to <strong>Paswal Tours</strong> — At Paswal Tours, your
            premier travel partner, we are dedicated to unlocking the globe for
            explorers and pilgrims alike. As an IATA-accredited agency, we
            specialize in delivering a wide range of high-quality travel
            services, including personalized flights, bespoke holiday packages,
            and spiritually enriching Umrah pilgrimages.
          </p>
          <p className="text-md md:text-md">
            At <strong>Paswal Tours</strong>, we prioritize personalized
            service, ensuring that each trip is meticulously crafted to meet
            your specific travel needs and expectations. As an esteemed IATA
            member, we adhere to the highest standards of quality and
            reliability within the travel industry. Our comprehensive travel
            solutions cover everything from securing visas and travel insurance
            to arranging accommodations and ground transportation, providing you
            with a seamless travel experience. Above all, customer satisfaction
            remains our top priority; we deeply value our clients' feedback and
            are continually striving to enhance our services to better meet your
            travel needs.
          </p>
          <NavLink to={"about-us"}>
            <button className="bg-tertiary-col text-white px-4 py-2 mt-5 md:px-6 md:py-3 rounded-full shadow-lg hover:bg-gray-600 transition-colors duration-300">
              Read More
            </button>
          </NavLink>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
