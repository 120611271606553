import React, { useState } from "react";
import emailjs from "emailjs-com";
// import backgroundImage from "../assets/airplane_wings.webp";

export const images = {
  airplaneWings:
    "https://res.cloudinary.com/dv9henoh0/image/upload/v1720706928/nz3hvkyceaezheh8fhvw.webp",
};

const FlightForm = () => {
  const [tripType, setTripType] = useState("one-way");
  const [classType, setClassType] = useState("economy");
  const [adults, setAdults] = useState(1);
  const [children, setChildren] = useState(0);
  const [infants, setInfants] = useState(0);
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    flyingFrom: "",
    flyingTo: "",
    departing: "",
    returning: "",
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const templateParams = {
      from_name: formData.fullName,
      email: formData.email,
      message: `
        Email: ${formData.email}
        Flying From: ${formData.flyingFrom}
        Flying To: ${formData.flyingTo}
        Departing: ${formData.departing}
        Returning: ${
          formData.returning !== "" ? formData.returning : "not-mentioned"
        }
        Trip Type: ${tripType}
        Class Type: ${classType}
        Adults: ${adults}
        Children: ${children}
        Infants: ${infants}
      `,
    };

    emailjs
      .send(
        "service_ob2qnjs",
        "template_09hf0nc",
        templateParams,
        "XH8z979n83zVvBoEh"
      )
      .then(
        (response) => {
          console.log("SUCCESS!", response.status, response.text);
          alert(
            "You'll Receive An Email Of Price Quotation Within 24 hours. Thank You!"
          );
        },
        (error) => {
          console.log("FAILED...", error);
          alert(
            "Failed to send the submit your request, please try again later."
          );
        }
      );
  };

  return (
    <div
      className="relative flex flex-col lg:flex-row items-center justify-center min-h-screen bg-cover bg-center"
      style={{ backgroundImage: `url(${images.airplaneWings})`, height: "1100px" }}
    >
      <div className="absolute inset-0 bg-black opacity-50"></div>
      <div className="relative z-10 p-6 text-white text-center lg:text-left lg:max-w-md">
        <h2 className="text-2xl font-bold mb-4">Book Your Flight Today</h2>
        <p className="mb-6 text-sm">
          Ready to explore the world? Book your flight today and embark on an
          unforgettable journey. Discover new destinations, experience different
          cultures, and create lasting memories.
        </p>
      </div>
      <div className="relative z-10 bg-white rounded-lg shadow-lg max-w-md w-full p-6">
        <h2 className="text-2xl font-bold mb-6 text-center">
          Book Your Flight
        </h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="block text-gray-700">Full Name</label>
            <input
              type="text"
              name="fullName"
              className="w-full p-2 border border-gray-300 rounded-md"
              placeholder="Full Name"
              value={formData.fullName}
              onChange={handleChange}
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700">Email Address</label>
            <input
              type="email"
              name="email"
              className="w-full p-2 border border-gray-300 rounded-md"
              placeholder="Email Address"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700">Flying From</label>
            <input
              type="text"
              name="flyingFrom"
              className="w-full p-2 border border-gray-300 rounded-md"
              placeholder="City or Airport"
              value={formData.flyingFrom}
              onChange={handleChange}
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700">Flying To</label>
            <input
              type="text"
              name="flyingTo"
              className="w-full p-2 border border-gray-300 rounded-md"
              placeholder="City or Airport"
              value={formData.flyingTo}
              onChange={handleChange}
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700">Departing</label>
            <input
              type="date"
              name="departing"
              className="w-full p-2 border border-gray-300 rounded-md"
              value={formData.departing}
              onChange={handleChange}
              required
            />
          </div>
          {tripType === "round-trip" && (
            <div className="mb-4">
              <label className="block text-gray-700">Returning</label>
              <input
                type="date"
                name="returning"
                className="w-full p-2 border border-gray-300 rounded-md"
                value={formData.returning}
                onChange={handleChange}
                required
              />
            </div>
          )}
          <div className="mb-4 flex flex-col sm:flex-row items-center">
            <div className="w-full sm:w-1/3 mb-2 sm:mb-0">
              <label className="block text-gray-700">Adults</label>
              <input
                type="number"
                className="w-full p-2 border border-gray-300 rounded-md"
                value={adults}
                onChange={(e) => setAdults(e.target.value)}
                min="1"
                required
              />
            </div>
            <div className="w-full sm:w-1/3 mb-2 sm:mb-0 sm:ml-4">
              <label className="block text-gray-700">Children</label>
              <input
                type="number"
                className="w-full p-2 border border-gray-300 rounded-md"
                value={children}
                onChange={(e) => setChildren(e.target.value)}
                min="0"
              />
            </div>
            <div className="w-full sm:w-1/3 sm:ml-4">
              <label className="block text-gray-700">Infants</label>
              <input
                type="number"
                className="w-full p-2 border border-gray-300 rounded-md"
                value={infants}
                onChange={(e) => setInfants(e.target.value)}
                min="0"
              />
            </div>
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm md:text-base">
              Trip Type
            </label>
            <div className="flex items-center text-sm md:text-base justify-around">
              <label className="mr-4">
                <input
                  type="radio"
                  name="tripType"
                  value="one-way"
                  checked={tripType === "one-way"}
                  onChange={() => setTripType("one-way")}
                  className="mr-1"
                />
                One Way
              </label>
              <label className="mr-4">
                <input
                  type="radio"
                  name="tripType"
                  value="round-trip"
                  checked={tripType === "round-trip"}
                  onChange={() => setTripType("round-trip")}
                  className="mr-1"
                />
                Round Trip
              </label>
              <label>
                <input
                  type="radio"
                  name="tripType"
                  value="multi-city"
                  checked={tripType === "multi-city"}
                  onChange={() => setTripType("multi-city")}
                  className="mr-1"
                />
                Multi-City
              </label>
            </div>
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm md:text-base">
              Travel Class
            </label>
            <div className="flex items-center text-sm md:text-base justify-around">
              <label className="mr-4">
                <input
                  type="radio"
                  name="classType"
                  value="economy"
                  checked={classType === "economy"}
                  onChange={() => setClassType("economy")}
                  className="mr-1"
                />
                Economy
              </label>
              <label className="mr-4">
                <input
                  type="radio"
                  name="classType"
                  value="business"
                  checked={classType === "business"}
                  onChange={() => setClassType("business")}
                  className="mr-1"
                />
                Business
              </label>
              <label>
                <input
                  type="radio"
                  name="classType"
                  value="first"
                  checked={classType === "first"}
                  onChange={() => setClassType("first")}
                  className="mr-1"
                />
                First Class
              </label>
            </div>
          </div>
          <div className="text-center">
            <button
              type="submit"
              className="bg-tertiary-col text-white w-full px-4 py-2 mt-3 md:px-6 md:py-3 font-bold rounded-full shadow-lg hover:bg-gray-600 transition-colors duration-300"
            >
              Quote Price
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default FlightForm;
