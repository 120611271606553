import React from 'react';
import './App.css';
import NavBar from './components/NavBar';
import Footer from './components/Footer';
import Home from './components/HomePage';
import AboutUsDetail from './components/AboutUsDetail';
import IntroPage from './components/IntroPage';
import { Route, Routes, useLocation } from 'react-router-dom';
import Destination from './components/Destination';
import ServicesPage from './components/ServicesPage';
import ServiceDetail from './components/ServiceDetail';

function App() {
  const location = useLocation(); // Get the current location
  const showNavBarAndFooter = location.pathname !== '/'; // Condition to show NavBar and Footer

  return (
    <>
      {showNavBarAndFooter && <NavBar/>}
      <Routes>
        <Route path='/' element={<IntroPage/>}/>
        <Route path="/home" element={<Home />} />
        <Route path='/about-us' element={<AboutUsDetail/>}/>
        <Route path='/home/about-us' element={<AboutUsDetail/>}/>
        <Route path='/destination' element={<Destination/>}/>
        <Route path='/services' element={<ServicesPage/>}/>
        <Route path="/service-detail/:serviceId" element={<ServiceDetail/>} />
      </Routes>
      {showNavBarAndFooter && <Footer/>}
    </>
  );
}

export default App;
