import React, { useState } from "react";
import { MenuIcon, XIcon } from "@heroicons/react/outline";
import { NavLink } from "react-router-dom";
// import Logo from "../assets/paswal_tours_logo.png";

export const images = {
  paswalLogo:
    "https://res.cloudinary.com/dv9henoh0/image/upload/v1720706928/mt75rjeeg9veamdgcdfv.png",
};

const NavBar = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="bg-primary-col fixed top-0 w-full z-50 flex items-center p-4 font-poppins text-sm justify-between">
      <NavLink to="/">
        <img src={images.paswalLogo} className="w-40" />
      </NavLink>
      {/* Hamburger Menu Button */}
      <div className="md:hidden">
        <button onClick={() => setIsOpen(!isOpen)}>
          {isOpen ? (
            <XIcon className="h-6 w-6 text-white" />
          ) : (
            <MenuIcon className="h-6 w-6 text-white" />
          )}
        </button>
      </div>

      {/* Navigation NavLinks */}
      <div
        className={`${
          isOpen ? "flex" : "hidden"
        } flex-col sm:flex-row md:flex space-x-0 md:space-x-4 flex-grow items-center justify-center md:items-center`}
      >
        <NavLink
          exact
          to="/home"
          activeClassName="nav-link-active"
          className="nav-link text-secondary-col mt-4 sm:mt-0"
        >
          Home
        </NavLink>
        <NavLink
          to="/about-us"
          activeClassName="nav-link-active"
          className="nav-link text-secondary-col mt-4 md:mt-0"
        >
          About Us
        </NavLink>
        <NavLink
          to="/services"
          activeClassName="nav-link-active"
          className="nav-link text-secondary-col mt-4 md:mt-0"
        >
          Services
        </NavLink>
        <NavLink
          to="/destination"
          activeClassName="nav-link-active"
          className="nav-link text-secondary-col mt-4 md:mt-0"
        >
          Destination
        </NavLink>
      </div>
    </div>
  );
};

export default NavBar;
