import React from "react";
import {
  FaFacebookF,
  FaInstagram,
  FaTwitter,
  FaPhone,
  FaMapMarkerAlt,
  FaEnvelope,
  FaRegClock,
} from "react-icons/fa";
// import Logo from "../assets/paswal_tours_logo.png";
import { NavLink, Link } from "react-router-dom";
// import AppConstants from '../constants/app_constants';

export const images = {
  paswalLogo:
    "https://res.cloudinary.com/dv9henoh0/image/upload/v1720706928/mt75rjeeg9veamdgcdfv.png",
};

const Footer = () => {
  return (
    <footer
      className="bg-primary-col text-white"
      style={{ borderRadius: "20px" }}
    >
      <div className="flex justify-center items-center">
        <div className="max-w-6xl w-full mx-auto px-4 py-8">
          <div className="grid grid-cols-1 md:grid-cols-8 gap-8 text-center md:text-start lg:text-start items-between text-md">
            <div className="col-span-2 md:col-span-2">
              <h3 className="font-bold mb-4">About Us</h3>
              <div className="w-20 h-1 bg-tertiary-col mx-auto lg:mx-0 lg:ml-0 rounded mt-2 mb-4"></div>
              <p>
                Welcome to Paswal Tours — At Paswal Tours, your premier travel
                partner, we are dedicated to unlocking the globe for explorers
                and pilgrims alike. As an IATA-accredited agency, we specialize
                in delivering a wide range of high-quality travel services,
                including personalized flights, bespoke holiday packages, and
                spiritually enriching Umrah pilgrimages.
              </p>
            </div>
            <div className="col-span-2 md:col-span-2">
              <h2 className="font-bold mb-4">Quick Links</h2>
              <div className="w-20 h-1 bg-tertiary-col mx-auto lg:mx-0 lg:ml-0 rounded mt-2 mb-4"></div>
              <ul>
                <Link to="/about-us/" className="mt-4 hover-color-transition">
                  <li>About Us</li>
                </Link>
                <Link to="/home/" className="mt-4 hover-color-transition">
                  <li>Home</li>
                </Link>
                <Link to="/services/" className="mt-4 hover-color-transition">
                  <li>Services</li>
                </Link>
                <Link
                  to="/destination/"
                  className="mt-4 hover-color-transition"
                >
                  <li>Destination</li>
                </Link>
              </ul>
            </div>
            <div className="col-span-2 md:col-span-2">
              <h3 className="font-bold mb-4">Stay In Touch</h3>
              <div className="w-20 h-1 bg-tertiary-col mx-auto lg:mx-0 lg:ml-0 rounded mt-2 mb-4"></div>
              <ul>
                <p className="flex text-sm items-center justify-center md:justify-start mb-2">
                  <FaPhone className="mr-3 icon-color" />
                  01204 774556
                </p>
                <p className="flex text-sm items-center justify-center md:justify-start mb-2">
                  <FaPhone className="mr-3 icon-color" />
                  01204 660305 
                </p>
                <p className="flex text-sm items-center justify-center md:justify-start mb-2">
                  <FaRegClock className="mr-3 icon-color" />
                  Available 24hrs
                </p>
                <p className="flex text-sm items-center justify-center md:justify-start mb-2">
                  <FaMapMarkerAlt className="mr-3 icon-color" />
                  44 St Helen's Road Bolton, BL3 3NH
                </p>
                <p className="flex text-sm items-center justify-center md:justify-start mb-2">
                  <FaEnvelope className="mr-3 icon-color" />
                  info@paswaltours.com
                </p>
              </ul>
            </div>
            <div className="col-span-2 md:col-span-2">
              <NavLink
                to="/"
                className="flex md:justify-start justify-center md:items-start"
              >
                <img src={images.paswalLogo} />
              </NavLink>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-light-blue-col flex justify-between w-full pl-16 pr-16 pb-4 items-center">
        <p className="icon-color" style={{ fontSize: "18px" }}>
          <span>&#169;</span> Created By Paswal Tours
        </p>
        <div className="flex justify-center space-x-4">
          <a href="#" className="hover:underline">
            <FaFacebookF className="hover-color-transition social-icon" />
          </a>
          <a href="#" className="hover:underline">
            <FaInstagram className="hover-color-transition social-icon" />
          </a>
          <a href="#" className="hover:underline">
            <FaTwitter className="hover-color-transition social-icon" />
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
