import Carousel from "./Carousel";
import AboutUs from "./AboutUs";
import Reviews from "./Reviews";

function Home() {
  return (
    <div>
      <Carousel />
      <Reviews />
      <AboutUs />
    </div>
  );
}

export default Home;
