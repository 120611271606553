import React, { useState, useEffect } from "react";
// import backgroundImage from "../assets/services.webp";
import ServicesSection from "./ServicesSection";
import { useLocation } from "react-router-dom";

export const images = {
  services:
    "https://res.cloudinary.com/dv9henoh0/image/upload/v1720706929/zxmybz7gosromqoxfkva.webp",
};

export default function ServicesPage() {
  const location = useLocation();
  const [fade, setFade] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    setFade(true);
  }, [location]);
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div className="font-poppins">
      <div
        className="relative text-white font-poppins w-full flex items-center h-screen flex-col justify-center"
        style={{
          backgroundImage: `url(${images.services})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="absolute inset-0 bg-black bg-opacity-50"></div>
        <div className="z-10 text-center">
          <h2
            className="text-4xl lg:text-6xl font-bold"
            style={{
              animation: fade ? "slideUp 1s ease-in-out" : "none",
              color: "#41acda",
            }}
          >
            Services
          </h2>
          <h1
            className="mt-4 text-xl lg:text-4xl"
            style={{
              animation: fade ? "slideUp 2s ease-in-out" : "none",
            }}
          >
            Discover, Explore, and Experience with Paswal Tours.
          </h1>
        </div>
      </div>
      <div
        className="container mx-auto px-4 sm:px-6 lg:px-8 py-8 font-poppins"
        style={{
          background: "linear-gradient(to bottom, #272f32 50%, #ffffff 50%)",
        }}
      >
        <ServicesSection />
      </div>
    </div>
  );
}
