import React from "react";
import "../App.css";
// import summer from "../assets/summer.webp";
// import winter from "../assets/winter.webp";

export const images = {
  summer:
    "https://res.cloudinary.com/dv9henoh0/image/upload/v1720706929/iycit4wsftcmurpliegh.webp",
  winter:
    "https://res.cloudinary.com/dv9henoh0/image/upload/v1720706932/auqt1fpzte5hq82wohdt.webp",
};

const GalleryComponent = () => {
  return (
    // Using Tailwind's padding and margin utilities responsively
    <div className="flex flex-col md:flex-row justify-center items-center space-y-4 md:space-y-0 md:space-x-4 h-screen w-full text-center px-5 md:px-20 pt-8 pb-8">
      {/* Summer Bookings */}
      <div className="relative w-full md:w-1/2 h-full md:h-full overflow-hidden rounded-lg shadow-lg hover:shadow-xl transition duration-300">
        <img
          src={images.summer}
          alt="Summer Bookings"
          className="absolute inset-0 w-full h-full object-cover transform scale-100 transition duration-500 hover:scale-110"
        />
        <p className="absolute top-1/2 left-1/4 right-1/4 transform -translate-x-1/2 -translate-y-1/2 text-white font-dancing-script text-3xl md:text-6xl pointer-events-none">
          Summer Bookings!
        </p>
      </div>

      {/* Winter Bookings */}
      <div className="relative w-full md:w-1/2 h-full md:h-full overflow-hidden rounded-lg shadow-lg hover:shadow-xl transition duration-300 mt-4 md:mt-0">
        <img
          src={images.winter}
          alt="Winter Bookings"
          className="absolute inset-0 w-full h-full object-cover transform scale-100 transition duration-500 hover:scale-110"
        />
        <p className="absolute top-1/2 left-1/4 right-1/4 transform -translate-x-1/2 -translate-y-1/2 text-white font-dancing-script text-3xl md:text-6xl pointer-events-none">
          Winter Bookings!
        </p>
      </div>
    </div>
  );
};

export default GalleryComponent;
