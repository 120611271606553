import React from "react";
import ServiceCard from "./ServiceCard";
import AppConstants from "../constants/app_constants";

const ServicesSection = () => {
  return (
    <div className="relative min-h-screen md:flex font-poppins my-10">
      <div className="flex-1  w-full md:h-1/2 py-10 px-5 flex flex-col justify-center items-center bg-tertiary-col" style={{borderRadius: "20px"}}>
        <div className="max-w-md mx-auto md:max-w-none text-center">
          <h2 className="text-3xl font-bold text-white">
            Paswal Tours Services
          </h2>
          <div className="flex items-center justify-center py-5">
            <p className="text-gray-900 italic text-sm md:text-lg">
              Looking for the best travel services around you? Come to Paswal
              Tours, where our friendly team welcomes you with open arms.
              Whether it's personal travel planning before or after our tours,
              or any other travel needs, we're here to assist you with all your
              inquiries and ensure your journey is seamless and enjoyable.
            </p>
          </div>
          <div className=" grid grid-cols-1 md:grid-cols-3 gap-6 mt-4 justify-items-center items-center">
            {AppConstants.services.map((service, index) => (
              <ServiceCard
                title={service.title}
                description={service.short_description}
                serviceId={service.id}
                shortImage={service.shortImage}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServicesSection;
