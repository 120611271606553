import React from "react";

const TravelCard = ({ image, title, description }) => {
  return (
    <div className="relative w-full md:w-1/5 h-96 overflow-hidden ">
      <img
        src={image}
        alt={title}
        className="absolute inset-0 w-full h-full object-cover z-0"
        style={{borderRadius: "20px"}}
      />

      {/* Gradient Overlay */}
      <div
        style={{
          position: "absolute",
          bottom: 0,
          left: 0,
          width: "100%",
          height: "50%",
          borderRadius: "20px",
          backgroundImage:
            "linear-gradient(to top, rgba(0,0,0,0.5), transparent)",
        }}
      ></div>

      {/* Text Content */}
      <div className="absolute bottom-0 p-4 z-10 text-center">
        <h3 className="text-white text-3xl font-bold font-dancing-script">{title}</h3>
        <p className="text-white text-sm">{description}</p>
      </div>
    </div>
  );
};

export default TravelCard;
